// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useState } from "react";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "../../../examples/Navbars/DefaultNavbar";

// Routes
import routes_navbar from "../../../routes_navbar";

import "../../../assets/styles.css";

import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from "chart.js";
import { Radar } from "react-chartjs-2";

import "../games.css";

import NBAJSON from "./NBATeams.json";
import NBAPlayers from "./NBAPlayers.json";
import ScheduleJSON from "./GameData.json";

import {
  getWriteup,
  getGraphScores,
  predictScores,
  getImageName,
  playerSelect,
} from "./NBACommon";
import { convertDate, getWinner } from "../../../utils/util";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

let graphOptions = {
  scales: {
    r: {
      beginAtZero: true,
      ticks: {
        stepSize: 5,
      },
    },
  },
};

let output = ScheduleJSON[0];
let players = NBAPlayers;

let teamData = NBAJSON.teamData;

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const gameId = urlParams.get("id");
const gameDateId = urlParams.get("date");

if (gameId && gameDateId != null) {
  output = ScheduleJSON.find(
    (item) => item.description == gameId && item.gamedate == gameDateId
  );
}

if (window.location.pathname.includes("NFL-")) {
  output = {
    title: "San Antonio Spurs at Detroit Pistons",
    description: "SA @ DET",
    sport: "basketball",
    gamedate: "2022-01-02",
    gametime: "00:00:00",
    images: ["San Antonio Spurs.jpg", "Detroit Pistons.jpg"],
    teams: ["San Antonio Spurs", "Detroit Pistons"],
    writeup: 2,
  };
}

let convertedDate;
if (output.gametime.startsWith("00:") || output.gametime.startsWith("01:")) {
  convertedDate = convertDate(output.gamedate, true);
} else {
  convertedDate = convertDate(output.gamedate, false);
}

let team1Name = output.teams[0];
let team2Name = output.teams[1];

let team1Players = [];
let team2Players = [];

let x = players[team1Name];
for (var key in x) {
  team1Players.push(key);
}

let y = players[team2Name];
for (var key in y) {
  team2Players.push(key);
}

let PlayerName1 = team1Players[0];
let PlayerName2 = team2Players[0];

let points = predictScores(
  players,
  PlayerName1,
  PlayerName2,
  team1Name,
  team2Name,
  0,
  0,
  0,
  0
);
let graphData = getGraphScores(
  PlayerName1,
  PlayerName2,
  team1Name,
  team2Name,
  players,
  teamData,
  output
);

let winner = getWinner(points, output);
let writeup = getWriteup(winner, points, teamData, output);

let totalPoints = points[0] + points[1];
let pointDifference = Math.abs(points[0] - points[1]);

const Team1PlayerSelect = playerSelect(team1Players);

const Team2PlayerSelect = playerSelect(team2Players);

function NBAPredictionsArchived() {
  const [Player1ImageState, setPlayer1ImageState] = useState(
    getImageName(PlayerName1)
  );
  const [Player2ImageState, setPlayer2ImageState] = useState(
    getImageName(PlayerName2)
  );

  const [totalPointState, setTotalPointState] = useState(totalPoints);
  const [pointDifferenceState, setPointDifferenceState] =
    useState(pointDifference);
  const [winningTeamState, setWinningTeamState] = useState(winner);
  const [writeupState, setWriteupState] = useState(writeup);
  const [graphState, setGraphState] = useState(graphData);

  function updateStates() {
    PlayerName1 = document.getElementById("PlayerSelect1").value;
    PlayerName2 = document.getElementById("PlayerSelect2").value;

    setPlayer1ImageState(getImageName(PlayerName1));
    setPlayer2ImageState(getImageName(PlayerName2));

    points = predictScores(
      players,
      PlayerName1,
      PlayerName2,
      team1Name,
      team2Name,
      document.getElementById("ChemistrySelect1").value,
      document.getElementById("HealthSelect1").value,
      document.getElementById("ChemistrySelect2").value,
      document.getElementById("HealthSelect2").value
    );
    graphData = getGraphScores(
      PlayerName1,
      PlayerName2,
      team1Name,
      team2Name,
      players,
      teamData,
      output
    );
    winner = getWinner(points, output);
    writeup = getWriteup(winner, points, teamData, output);

    totalPoints = points[0] + points[1];
    pointDifference = Math.abs(points[0] - points[1]);

    setTotalPointState(totalPoints);
    setPointDifferenceState(pointDifference);
    setWinningTeamState(winner);
    setWriteupState(writeup);
    setGraphState(graphData);
  }

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes_navbar}
          action={{
            type: "internal",
            color: "info",
          }}
        />
      </MKBox>
      <MKBox pt={6} px={1} mt={12}>
        <MKTypography variant="h1" fontWeight="bold" align="center">
          {output.title}
        </MKTypography>
        <MKTypography variant="h4" fontWeight="bold" align="center">
          {convertedDate}
        </MKTypography>
      </MKBox>

      <MKBox pt={3} px={1} mt={3}>
        <MKTypography variant="h3" fontWeight="bold" align="center">
          Choose Each Team's Most Impactful Player to Analyze:
        </MKTypography>
      </MKBox>

      <MKBox pt={2} px={1} mt={3}>
        <table>
          <tr>
            <th>
              <img
                src={
                  "/players/NBA/" +
                  teamData[output.teams[0]].images +
                  "/" +
                  Player1ImageState +
                  ".png"
                }
                alt={PlayerName1 + " (Picture Not Found)"}
                width="50%"
              />
            </th>
            <th>
              <img
                src={
                  "/players/NBA/" +
                  teamData[output.teams[1]].images +
                  "/" +
                  Player2ImageState +
                  ".png"
                }
                alt={PlayerName2 + " (Picture Not Found)"}
                width="50%"
              />
            </th>
          </tr>
          <tr>
            <th>
              <select
                className="PlayerSelect"
                id="PlayerSelect1"
                onChange={updateStates}
              >
                {Team1PlayerSelect}
              </select>
            </th>
            <th>
              <select
                className="PlayerSelect"
                id="PlayerSelect2"
                onChange={updateStates}
              >
                {Team2PlayerSelect}
              </select>
            </th>
          </tr>
        </table>
      </MKBox>

      <MKBox pt={3} px={1} mt={3}>
        <MKTypography variant="h3" fontWeight="bold" align="center">
          Choose Human-Assisted Fine Tuning Modifiers:
        </MKTypography>
        <MKTypography variant="h5" fontWeight="bold" align="center">
          (These are optional. Leave them on "Average" if you don't wish to use
          them.)
        </MKTypography>
      </MKBox>

      <MKBox pt={2} px={1} mt={3}>
        <table>
          <tr>
            <th>{teamData[output.teams[0]].short} Team Chemistry Rating</th>
            <th>{teamData[output.teams[0]].short} Team Health Rating</th>
          </tr>
          <tr>
            <th>
              <select
                className="ChemistrySelect"
                id="ChemistrySelect1"
                onChange={updateStates}
              >
                <option value="0" selected>
                  Average
                </option>
                <option value="-4">Poor</option>
                <option value="-8">Bad</option>
                <option value="-12">Very Bad</option>
              </select>
            </th>
            <th>
              <select
                className="HealthSelect"
                id="HealthSelect1"
                onChange={updateStates}
              >
                <option value="0" selected>
                  Average
                </option>
                <option value="-4">Poor</option>
                <option value="-8">Bad</option>
                <option value="-12">Very Bad</option>
              </select>
            </th>
          </tr>
        </table>
      </MKBox>

      <MKBox pt={2} px={1} mt={3}>
        <table>
          <tr>
            <th>{teamData[output.teams[1]].short} Team Chemistry Rating</th>
            <th>{teamData[output.teams[1]].short} Team Health Rating</th>
          </tr>
          <tr>
            <th>
              <select
                className="ChemistrySelect"
                id="ChemistrySelect2"
                onChange={updateStates}
              >
                <option value="0" selected>
                  Average
                </option>
                <option value="-4">Poor</option>
                <option value="-8">Bad</option>
                <option value="-12">Very Bad</option>
              </select>
            </th>
            <th>
              <select
                className="HealthSelect"
                id="HealthSelect2"
                onChange={updateStates}
              >
                <option value="0" selected>
                  Average
                </option>
                <option value="-4">Poor</option>
                <option value="-8">Bad</option>
                <option value="-12">Very Bad</option>
              </select>
            </th>
          </tr>
        </table>
      </MKBox>

      <MKBox pt={3} px={1} mt={3}>
        <MKTypography variant="h2" fontWeight="bold" align="center">
          AI Prediction Results:
        </MKTypography>
      </MKBox>

      <MKBox pt={2} px={1} mt={3}>
        <table>
          <tr>
            <th>Winning Team</th>
            <th>Point Difference</th>
            <th>Total Game Points</th>
          </tr>
          <tr>
            <td>{winningTeamState}</td>
            <td>{pointDifferenceState}</td>
            <td>{totalPointState}</td>
          </tr>
        </table>
      </MKBox>

      <MKBox pt={3} px={1} mt={3}>
        <MKTypography variant="h2" fontWeight="bold" align="center">
          Player Image Analysis Graph Results:
        </MKTypography>
      </MKBox>

      <Container>
        <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
          <Radar name="Chart" options={graphOptions} data={graphState} />
        </Grid>
      </Container>

      <MKBox pt={3} px={1} mt={3} mb={3}>
        <MKTypography variant="h2" fontWeight="bold" align="center">
          Analysis Summary:
        </MKTypography>
      </MKBox>

      <MKBox px={1} mb={6}>
        <MKTypography variant="body1" align="center">
          {writeupState}
        </MKTypography>
      </MKBox>
    </>
  );
}

export default NBAPredictionsArchived;
