// @mui material components

// Sections components
import { useEffect, useState } from "react";
import BaseLayout from "../../../layouts/sections/components/BaseLayout";
import MKBox from "../../../components/MKBox";

import { useNavigate } from "react-router-dom";

import "./pricing.css";

// Material Kit 2 React components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import DefaultInfoCard from "../../../examples/Cards/InfoCards/DefaultInfoCard";
import MKBadge from "../../../components/MKBadge";
import MKTypography from "../../../components/MKTypography";
import MKButton from "../../../components/MKButton";

const ProductDisplay = ({}) => (
  <>
    <MKBox component="section" py={6} my={6}>
      <Grid
        container
        item
        xs={12}
        spacing={3}
        alignItems="center"
        sx={{ mx: "auto" }}
      >
        <Grid
          container
          spacing={3}
          justifyContent="center"
          padding={3}
          backgroundColor="#03399e"
        >
          <Grid item xs={12} md={4} mx={1} mb={3} align="center">
            <section className="pricingWhite">
              <MKTypography
                variant="body1"
                fontWeight="bold"
                color="text"
                align="center"
              >
                DEFAULT TIER
              </MKTypography>
              <MKTypography variant="h1" fontWeight="bold" align="center">
                Free
              </MKTypography>
              <hr></hr>
              <br></br>
              <MKTypography variant="body1" align="left" mb={2} mx={2}>
                <i class="fa fa-check"></i> Free Pick of the Week
              </MKTypography>
              <MKTypography variant="body1" align="left" mb={2} mx={2}>
                <i class="fa fa-check"></i> Archived Game Predictions
              </MKTypography>
              <MKTypography
                variant="body1"
                color="text"
                align="left"
                mb={2}
                mx={2}
              >
                <i class="fa fa-times"></i> Upcoming Game Predictions
              </MKTypography>
              <MKTypography
                variant="body1"
                color="text"
                align="left"
                mb={2}
                mx={2}
              >
                <i class="fa fa-times"></i> Point Differential Predictions
              </MKTypography>
              <MKTypography
                variant="body1"
                color="text"
                align="left"
                mb={2}
                mx={2}
              >
                <i class="fa fa-times"></i> Total Score Predictions
              </MKTypography>
              <MKTypography
                variant="body1"
                color="text"
                align="left"
                mb={2}
                mx={2}
              >
                <i class="fa fa-times"></i> Image Analysis Graphs
              </MKTypography>
              <br></br>
              <MKButton
                href="/games/Free-pick/NFL"
                variant={"gradient"}
                color={"info"}
                size="large"
                width="100%"
                align="center"
              >
                {"Check it Out"}
              </MKButton>
            </section>
          </Grid>
          <Grid item xs={12} md={4} mx={1} mb={3} align="center">
            <section className="pricingWhite">
              <MKTypography
                variant="body1"
                fontWeight="bold"
                color="text"
                align="center"
              >
                PRO TIER
              </MKTypography>
              <MKTypography variant="h1" fontWeight="bold" align="center">
                $8/month
              </MKTypography>
              <hr></hr>
              <br></br>
              <MKTypography variant="body1" align="left" mb={2} mx={2}>
                <i class="fa fa-check"></i> Free Pick of the Week
              </MKTypography>
              <MKTypography variant="body1" align="left" mb={2} mx={2}>
                <i class="fa fa-check"></i> Archived Game Predictions
              </MKTypography>
              <MKTypography variant="body1" align="left" mb={2} mx={2}>
                <i class="fa fa-check"></i> Upcoming Game Predictions
              </MKTypography>
              <MKTypography variant="body1" align="left" mb={2} mx={2}>
                <i class="fa fa-check"></i> Point Differential Predictions
              </MKTypography>
              <MKTypography variant="body1" align="left" mb={2} mx={2}>
                <i class="fa fa-check"></i> Total Score Predictions
              </MKTypography>
              <MKTypography variant="body1" align="left" mb={2} mx={2}>
                <i class="fa fa-check"></i> Image Analysis Graphs
              </MKTypography>
              <br></br>
              <form
                id="checkoutForm"
                action="/api/v1/order/create-checkout-session"
                method="POST"
              >
                <input type="hidden" name="lookup_key" value="prod_1" />
                <input type="hidden" name="email" value={email} />
              </form>
            </section>
          </Grid>
        </Grid>
      </Grid>
    </MKBox>
  </>
);

function Pricing() {
  return (
    <BaseLayout title="Pricing Plans">
      <ProductDisplay />
    </BaseLayout>
  );
}

export default Pricing;
