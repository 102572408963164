/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

//MindGlimpse Pages
import NFLArchived_2022 from "./pages/Games/NFL_2022/NFLArchived_2022";
import NFLArchived_2023 from "./pages/Games/NFL_2023/NFLArchived_2023";
import NFLArchived_2024 from "./pages/Games/NFL_2024/NFLArchived_2024";
import NFLUpcoming from "./pages/Games/NFL_Generic/NFLUpcoming";

import NBAArchived from "./pages/Games/NBA/NBAArchived";
import NBAUpcoming from "./pages/Games/NBA/NBAUpcoming";
import NCAAPredictions from "./pages/Games/NCAA/NCAAPredictions";

const routes_navbar = [
  {
    name: "Games",
    icon: <Icon>date_range</Icon>,
    collapse: [
      {
        name: "Upcoming Games",
        dropdown: true,
        collapse: [
          {
            name: "NFL Upcoming",
            route: "/games/NFL-upcoming",
            component: <NFLUpcoming />,
          },
          {
            name: "NBA Upcoming",
            route: "/games/NBA-upcoming",
            component: <NBAUpcoming />,
          },
        ],
      },
      {
        name: "Archived Games",
        dropdown: true,
        collapse: [
          {
            name: "NFL Archives 2024",
            route: "/games/NFL-archived_2024",
            component: <NFLArchived_2024 />,
          },
          {
            name: "NFL Archives 2023",
            route: "/games/NFL-archived_2023",
            component: <NFLArchived_2023 />,
          },
          {
            name: "NFL Archives 2022",
            route: "/games/NFL-archived",
            component: <NFLArchived_2022 />,
          },
          {
            name: "NBA Archives 2022",
            route: "/games/NBA-archived",
            component: <NBAArchived />,
          },
        ],
      },
    ],
  },
  {
    name: "Blog Site",
    icon: <Icon>article</Icon>,
    href: "https://blog.mindglimpse.com/",
  },
  {
    name: "NCAA Games",
    route: "/NCAA",
    component: <NCAAPredictions />,
  },
];

export default routes_navbar;
