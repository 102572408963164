// @mui material components

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "../../../examples/Navbars/DefaultNavbar";
import MKTypography from "../../../components/MKTypography";

// Routes
import routes_navbar from "../../../routes_navbar";
import CenteredFooter from "../../../examples/Footers/CenteredFooter";

// Image

import { useNavigate } from "react-router-dom";

import "../../../assets/styles.css";
import NFLJSON from "../NFL_2024/NFLTeams_2024.json";
import output from "../NFL_2024/NFLGames.json";
import { fontSize } from "@mui/system";

import { convertDate, toIsoTime } from "../../../utils/util";

var teamData = NFLJSON.teamData;

var currentDate = new Date();
var endDate = new Date();
endDate.setDate(endDate.getDate() + 16);

currentDate = toIsoTime(currentDate);
endDate = toIsoTime(endDate);

function NFLUpcoming() {
  function openGame(gameId, gameDate) {
    let url =
      window.location.origin +
      "/games/NFL-predictions-upcoming/?id=" +
      gameId +
      "&date=" +
      gameDate;
    window.open(url, "_blank");
  }

  const DisplayData = output.map((info) => {
    if (info.gamedate >= currentDate && info.gamedate <= endDate) {
      if (info.gametime.startsWith("00:") || info.gametime.startsWith("01:")) {
        try {
          return (
            <tr
              className={"highlight"}
              onClick={() => openGame(info.description, info.gamedate)}
            >
              <td>
                <img
                  class="logo"
                  src={"/teams/" + teamData[info.teams[0]].short + ".webp"}
                  alt=""
                />{" "}
                vs.{" "}
                <img
                  class="logo"
                  src={"/teams/" + teamData[info.teams[1]].short + ".webp"}
                  alt=""
                />
                <div>
                  {teamData[info.teams[0]].short} @{" "}
                  {teamData[info.teams[1]].short}
                </div>
              </td>
              <td>{convertDate(info.gamedate, true)}</td>
            </tr>
          );
        } catch (error) {}
      }
      try {
        return (
          <tr
            className={"highlight"}
            onClick={() => openGame(info.description, info.gamedate)}
          >
            <td>
              <img
                class="logo"
                src={"/teams/" + teamData[info.teams[0]].short + ".webp"}
                alt=""
              />{" "}
              vs.{" "}
              <img
                class="logo"
                src={"/teams/" + teamData[info.teams[1]].short + ".webp"}
                alt=""
              />
              <div>
                {teamData[info.teams[0]].short} @{" "}
                {teamData[info.teams[1]].short}
              </div>
            </td>
            <td>{convertDate(info.gamedate, false)}</td>
          </tr>
        );
      } catch (error) {}
    }
  });

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes_navbar}
          action={{
            type: "internal",
            color: "info",
          }}
        />
      </MKBox>

      <MKBox pt={6} px={1} mt={12}>
        <MKTypography variant="h1" fontWeight="bold" align="center">
          NFL Upcoming Games
        </MKTypography>

        <table>
          <thead>
            <tr>
              <th>Game</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>{DisplayData}</tbody>
        </table>
      </MKBox>

      <MKBox pt={6} px={1} mt={12}>
        <CenteredFooter />
      </MKBox>
    </>
  );
}

export default NFLUpcoming;
