// Convenience functions for NFL pages
export function getWriteup(winner, points, teamData, output) {
    var awayTeam = teamData[output.teams[0]].short
    var homeTeam = teamData[output.teams[1]].short
    var pointTotal = points[0] + points[1]
    var writeup = ""

    if (winner == "Toss Up") {
        writeup = "The " + awayTeam + " are away this week, playing the "
            + homeTeam + " at home. Micro-expression analysis finds that the game will be very close, potentially ending in a tie. Micro-expression and quantitative analysis suggest the total predicted points in this game to be "
            + pointTotal + " point(s). In this case, Mindglimpse recommends taking the points based on the total over/under line coming out of Vegas. Also, pay close attention to the quarterback's psychological profile graphs, as any slight edge could be the difference in breaking this predicted tie. Enjoy the game."
    } else {

        var winningTeam = teamData[winner].short
        var pointDifference = Math.abs(points[0] - points[1])

        switch (output.writeup) {
            case 0:
                writeup = "In this week's game, the " + awayTeam + " are the visiting team playing the " + homeTeam + " at home. Micro-expression analysis indicates the "
                    + winningTeam + " will win this game by " + pointDifference + " point(s). Micro-expression analysis and historical data find this game's total predicted points to be "
                    + pointTotal + ". In brief, Mindglimpse recommends that if one were to wager on this game, consider the point spread and total over/under line coming out of Vegas. Also, please pay close attention to the visual breakdown highlighting the micro-expression analysis assessments of the "
                    + awayTeam + " and " + homeTeam + " and their respective starting quarterbacks. As always, you may wish to weigh the QB psychological profiles more than the forecasted outcome; it is your choice. Enjoy the game."
                break;
            case 1:
                writeup = "The " + awayTeam + " are the visiting team, playing the " + homeTeam + " at home this week. Micro-expression analysis finds that the "
                    + winningTeam + " will win this game by " + pointDifference + " point(s). Micro-expression and quantitative research suggest that this game's total predicted points is "
                    + pointTotal + ". As such, Mindglimpse recommends considering the point spread and total over/under line coming out of Vegas if one were to bet on this game. Also, pay close attention to the breakdown highlighting the micro-expression analysis assessments of the "
                    + awayTeam + " and " + homeTeam + " and respective starting quarterbacks. As always, you may want to weigh the quarterback's psychological profile more than the forecasted outcome; it is up to you. Have fun."
                break;
            case 2:
                writeup = "In the NFL this week, the " + awayTeam + " are the visiting team playing the " + homeTeam + " at home. Micro-expression analysis indicates the "
                    + winningTeam + " will win this game by " + pointDifference + " point(s). Micro-expression analysis and historical data find this game's total predicted points to be "
                    + pointTotal + ". In brief, Mindglimpse recommends that if one were to wager on this game, consider the point spread and total over/under line coming out of Vegas. Also, pay close attention to the graphic breakdown highlighting the micro-expression analysis assessments of the "
                    + awayTeam + " and " + homeTeam + " and corresponding starting quarterbacks. As always, you may wish to weigh the QB psychological profiles; it is your choice. Enjoy."
                break;
            case 3:
                writeup = "The " + awayTeam + " are away this week, playing the " + homeTeam + " at home. Micro-expression analysis finds that the "
                    + winningTeam + " will win this game by " + pointDifference + " point(s). Micro-expression and quantitative analysis suggest the total predicted points in this game to be "
                    + pointTotal + ". As such, Mindglimpse recommends considering if one were to bet on this game, reflect on the point spread and total over/under line coming out of Vegas. Also, pay close attention to the graphic breakdown highlighting the micro-expression analysis assessments of the "
                    + awayTeam + " and " + homeTeam + " and respective starting quarterbacks. As always, you may want to weigh the quarterback's psychological profile more than the forecasted outcome; it is up to you."
                break;
            default:
                writeup = "The " + awayTeam + " are away this week, playing the " + homeTeam + " at home. Micro-expression analysis finds that the "
                    + winningTeam + " will win this game by " + pointDifference + " point(s). Micro-expression and quantitative analysis suggest the total predicted points in this game to be "
                    + pointTotal + ". As such, Mindglimpse recommends considering if one were to bet on this game, reflect on the point spread and total over/under line coming out of Vegas. Also, pay close attention to the graphic breakdown highlighting the micro-expression analysis assessments of the "
                    + awayTeam + " and " + homeTeam + " and respective starting quarterbacks. As always, you may want to weigh the quarterback's psychological profile more than the forecasted outcome; it is up to you."
                break;
        }
    }
    return (writeup);
}

export function predictScores(qb1, qb2, teamData, output) {

    var points1 = 0
    var points2 = 0

    try {
    var historical1 = teamData[output.teams[0]].historical
    var historical2 = teamData[output.teams[1]].historical

    points1 = Math.round(((output.playerPredictions[qb1] + historical1) / 2))
    points2 = Math.round(((output.playerPredictions[qb2] + historical2) / 2) + 1.9)

    } catch (error) {

    }
    
    var points = [points1, points2]

    return (points)
}

export function getGraphScores(qb1, qb2, teamData, output) {
    var i = 0
    var types = ["anger", "disgust", "fear", "happy", "sad", "surprise", "neutral"]
    var qbGraph1 = [0, 0, 0, 0, 0, 0, 0]
    var qbGraph2 = [0, 0, 0, 0, 0, 0, 0]

    var team1back = "rgba(" + teamData[output.teams[0]].priColor + ", .5)"
    var team1border = "rgba(" + teamData[output.teams[0]].priColor + ", 1)"
    var team2back = "rgba(" + teamData[output.teams[1]].priColor + ", .5)"
    var team2border = "rgba(" + teamData[output.teams[1]].priColor + ", 1)"

    for (i = 0; i < types.length; i++) {
        var score1 = (output.graph[qb1][types[i]])
        var score2 = (output.graph[qb2][types[i]])

        if (score1 > score2) {
            qbGraph1[i] = 10;
            qbGraph2[i] = 5;
        }
        else if (score2 > score1) {
            qbGraph1[i] = 5;
            qbGraph2[i] = 10;
        }
        else {
            qbGraph1[i] = 10;
            qbGraph2[i] = 10;
        }
    }

    var graphData = {
        labels: ['Intensity', 'Caution', 'Stress', 'Confidence', 'Energy', 'Game Prep', 'Strategy'],
        datasets: [
            {
                label: qb1 + " (" + teamData[output.teams[0]].short + ")",
                data: qbGraph1,
                backgroundColor: "rgba(128,128,128,.5)",
                borderColor: "rgba(0,0,0,1)",
                borderWidth: 1,
            },
            {
                label: qb2 + " (" + teamData[output.teams[1]].short + ")",
                data: qbGraph2,
                backgroundColor: team2back,
                borderColor: "rgba(0,0,0,1)",
                borderWidth: 1,
            },
        ],
    };

    return (graphData)
}

export function getImageName(team, qb, output) {
    return (output.teams[team] + " " + qb).replace(/\s+/g, '-')
}

export function QBSelect(output, position) {
    return output.whatever[position].map(
        (info) => {
            return (
                <option value={info}>{info}</option>
            )
        }
    )
}